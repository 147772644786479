<template>
  <div id="snakeContainer" class="container mx-auto" style="max-width: 500px;">
    <table class="snake-table">
      <tbody class="snake-table-body">

      </tbody>
    </table>

    <div class="text-center">
      <div>
        <button class="btn btn-outline-primary m-1 change-snake-direction" value="3"><i class="bi bi-arrow-up-circle-fill"></i></button>
      </div>
      <div>
        <button class="btn btn-outline-primary mx-3 m-1 change-snake-direction" value="2"><i class="bi bi-arrow-left-circle-fill"></i></button>
        <button class="btn btn-outline-primary mx-3 m-1 change-snake-direction" value="0"><i class="bi bi-arrow-right-circle-fill"></i></button>
      </div>
      <div>
        <button class="btn btn-outline-primary m-1 change-snake-direction" value="1"><i class="bi bi-arrow-down-circle-fill"></i></button>
      </div>
      <div>
        <button class="btn btn-primary m-1" id="startGame">{{strings.StartGame}}</button>
      </div>
    </div>
    <p class="h3">
      {{strings.Instructions}}
    </p>
    <p>
      {{strings.SnakeGameInstructions}}
    </p>
  </div>
</template>

<script setup>
import { strings } from '../strings.js';
import $ from 'jquery';
import { onMounted } from 'vue';

$(function(){
  let startDirection = 0;
let previousDirection = 0;

let direction = 0;
let snake;
let fruit = [];

$("#startGame").on("click", function(){
  $("#startGame").prop("disabled", true);
  $(".snake-cell").css("background-color", "white")
  direction = startDirection;
  snake = [[2,2],[2,1],[2,0]];
  GenerateFruitLocation();
  SnakeGame();
})

$(".change-snake-direction").on("click", function(){
  let newDirection = $(this).val();
  ChangeDirection(newDirection);
});

$("#snakeContainer").on("swipe", handleSwipe);

function handleSwipe(event){
  console.log(event);
}

document.onkeydown = function(e){
  switch(e.which){
    case 39:
      e.preventDefault();
      ChangeDirection(0);
      break;
    case 40:
      e.preventDefault();
      ChangeDirection(1);
      break;
    case 37:
      e.preventDefault();
      ChangeDirection(2);
      break;
    case 38:
      e.preventDefault();
      ChangeDirection(3);
      break;
  }
}

function SnakeGame(){

  for(let i = snake.length; i>1; i--){
    snake[i-1] = snake[i-2];
  }

  switch(direction){
    case 0:
      snake[0] = [snake[0][0], snake[0][1]+1];
      break;
    case 1:
      snake[0] = [snake[0][0]+1, snake[0][1]];
      break;
    case 2:
      snake[0] = [snake[0][0], snake[0][1]-1];
      break;
    case 3:
      snake[0] = [snake[0][0]-1, snake[0][1]];
      break;
  }

  previousDirection = direction;

  if(JSON.stringify(snake[0]) == JSON.stringify(fruit)){
    snake[snake.length] = snake[snake.length - 1];
    GenerateFruitLocation();
  }

  if(snake[0][0] < 0 || snake[0][1] < 0 || snake[0][0] > 19 || snake[0][1] > 19){
    GameOver();
    return;
  }

  for(let i = 1; i < snake.length; i++){
    if(JSON.stringify(snake[0]) == JSON.stringify(snake[i])){
      GameOver();
      return;
    }
  }

  DrawSnake();

  setTimeout(SnakeGame, 200);
}

function GameOver(){
  $(".snake-cell").css("background-color", "lightpink")

  DrawSnake(true);
  
  $("#startGame").prop("disabled", false);
}

function DrawSnake(endGame){
  if(!endGame){
    $(".snake-cell").css("background-color", "white")
  }
  for(let i = 0; i < snake.length; i++){
    $(`.snake-row-${snake[i][0]} .snake-col-${snake[i][1]}`).css("background-color", "green")
  }
  $(`.snake-row-${fruit[0]} .snake-col-${fruit[1]}`).css("background-color", "purple")
  if(endGame){
    $(`.snake-row-${snake[0][0]} .snake-col-${snake[0][1]}`).css("background-color", "red")
    $(`.snake-row-${snake[1][0]} .snake-col-${snake[1][1]}`).css("background-color", "red")
  }
}

function GenerateFruitLocation(){
  do{
    fruit = [Math.floor(Math.random()*20), Math.floor(Math.random()*20)];
  }while(snake.includes(fruit))
}

function ChangeDirection(input){
  if((Number(input) + previousDirection) % 2 != 0){
    direction = Number(input);
  }
}
})

function loadSnakeTable() {
  const snakeTable = $('.snake-table-body');
  for (let i = 0; i < 20; i++) {
    const row = $(`<tr class='snake-row-${i}'></tr>`);

    snakeTable.append(row);
    
    for (let j = 0; j < 20; j++) {
      const cell = $(`<td class='snake-cell snake-col-${j}'></td>`);
      row.append(cell);
    }
  }
}

onMounted(() => {
  loadSnakeTable();
});
</script>

<style>
.snake-table{
  border-collapse: collapse;
  margin: auto;
}
.snake-table tbody td{
  border: 1px solid black;
  min-width: 18px;
}
.snake-table td{
  height: 18px;
}
</style>