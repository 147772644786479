<template>
    <div class="container" style="font-size: 1.15em;">
        <form :autocomplete="'off'" id="open-house-form" class="contact-form border row p-2 rounded" @submit.prevent="handleSubmit">
            <div v-html="openHouseInfo"></div>
            <div class="row">
                <div class="mb-1 col-lg-1">
                    <label for="name" style="position: relative; bottom: 3px" class="form-label">{{ strings.NameLabel }} <span class="text-danger">*</span></label>
                </div>
                <div class="mb-1 col-lg-11">
                    <input type="text" id="name" class="form-control" v-model="contact.name" required :placeholder="strings.NamePlaceholder" />
                </div>
            </div>
            <div class="row mt-2">
                <div class="mb-1 col-lg-1">
                    <label for="phone" style="position: relative; bottom: 3px" class="form-label">{{ strings.PhoneLabel }} <span class="text-danger">*</span></label>
                </div>
                <div class="mb-1 col-lg-5">
                    <input type="text" id="phone" class="form-control" v-model="contact.phone" :placeholder="strings.PhonePlaceholder" />
                </div>
                <div class="mb-1 col-lg-1">
                    <label for="email" style="position: relative; bottom: 3px" class="form-label">{{ strings.EmailLabel }} <span class="text-danger">*</span></label>
                </div>
                <div class="mb-1 col-lg-5">
                    <input type="email" id="email" class="form-control" v-model="contact.email" required :placeholder="strings.EmailPlaceholder" />
                </div>
            </div>
            <div class="row mt-2">
                <div class="mb-1 col-lg-5">
                    <label for="message" style="position: relative; bottom: 3px" class="form-label">{{ strings.OpenHouseMessageLabel }}</label>
                </div>
                <div class="mb-1 col-lg-7">
                    <textarea id="message" class="form-control" v-model="contact.message" :placeholder="strings.MessagePlaceholder"></textarea>
                </div>
            </div>
            <div class="row border-top m-0 mt-2">
                <div class="mb-1 col-lg-12">
                    <label for="name" class="form-label">{{ strings.AgentQuestion }} <span class="text-danger">*</span></label>
                    <input type="radio" class="ms-2" id="agentYes" value="yes" v-model="contact.agent" name="agent" required />
                    <label for="agentYes" class="form-label">{{ strings.AgentYes }}</label>
                    <input type="radio" class="ms-2" id="agentNo" value="no" v-model="contact.agent" name="agent" required />
                    <label for="agentNo" class="form-label">{{ strings.AgentNo }}</label>
                </div>
            </div>
            <div class="row border rounded p-2 m-0" :class="{ 'text-muted': contact.agent === 'no' || contact.agent === null }">
                <p v-html="strings.AgentRequest"></p>
                <div class="mb-1 col-md-2">
                    <label for="agentName" class="form-label">{{ strings.AgentNameLabel }}</label>
                </div>
                <div class="mb-1 col-lg-4">
                    <input type="text" id="agentName" class="form-control"
                           v-model="contact.agentName" :placeholder="strings.AgentNamePlaceholder"
                           :disabled="contact.agent === 'no' || contact.agent === null"
                           :required="contact.agent === 'yes'" />
                </div>
                <div class="mb-1 col-md-2">
                    <label for="agentCompany" class="form-label">{{ strings.AgentCompanyLabel }}</label>
                </div>
                <div class="mb-1 col-lg-4">
                    <input type="text" id="agentCompany" class="form-control"
                           v-model="contact.agentCompany" :placeholder="strings.AgentCompanyPlaceholder"
                           :disabled="contact.agent === 'no' || contact.agent === null"
                           :required="contact.agent === 'yes'" />
                </div>
            </div>
            <div class="row mt-2">
                <div class="d-flex justify-content-between">
                    <button type="submit" class="btn btn-secondary btn-md fw-bold" :disabled="submitDisabled" style="padding: 1em 2em;">{{ strings.SubmitButton }}</button>
                    <button type="reset" class="btn btn-link">{{ strings.ResetButton }}</button>
                </div>
            </div>
        </form>
        <div>
            <div v-html="openHouseThanks"></div>
        </div>
        <canvas id="qrCanvas"></canvas>
    </div>
</template>

<script setup>
import { strings } from '../strings.js';
import { ref, onMounted } from 'vue';
import QRCode from 'qrcode';
import api from '../api.js';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import { useRoute } from 'vue-router';

const route = useRoute();
const toast = useToast();

const openHouseInfo = ref('');
const openHouseThanks = ref('');

const contact = ref({
    name: '',
    phone: '',
    email: '',
    agent: null,
    agentName: '',
    agentCompany: '',
    message: '',
    property: openHouseInfo.value,
    openHouseId: route.params.id,
});

const resetForm = () => {
    contact.value.name = '';
    contact.value.phone = '';
    contact.value.email = '';
    contact.value.agent = null;
    contact.value.agentName = '';
    contact.value.agentCompany = '';
    contact.value.message = '';
};

const submitDisabled = ref(false);

function handleSubmit() {
    submitDisabled.value = true;
    api.createContact(contact.value, () => {
        toast.success(strings.OpenHouseConfirmation);
        // sleep for 3 seconds before clearing the form
        setTimeout(() => {
            resetForm();
            submitDisabled.value = false;
        }, 5000);
    }, () => {
        toast.error(strings.ContactError);
        submitDisabled.value = false;
    });
}

onMounted(() => {
    const id = route.params.id;
    api.fetchContent(id, (response) => { openHouseInfo.value = response; });
    api.fetchContent("openHouseThanks", (response) => { openHouseThanks.value = response; });

    if (window.isKioskMode) {
        const canvas = document.getElementById('qrCanvas');
        QRCode.toCanvas(canvas, window.location.href, (error) => {
            if (error) console.error(error);
        });
    }
});
</script>

<style scoped>
.form-label {
    text-wrap: nowrap;
}

#message {
    height: 1em;
}

#qrCanvas {
    position: absolute;
    bottom: 4px;
    right: 4px;
    width: 100px !important;
    height: 100px !important;
}

@media screen and (max-width: 600px) {
    #qrCanvas {
        display: none;
    }
    #open-house-form {
        padding: 0 !important;
    }
    .form-label {
        padding: 0;
    }
}

.text-muted {
    color: #9c959d !important;
}
</style>
